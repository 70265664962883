import axios from "axios";
const API_URL = "https://unbreakable.netlify.com/.netlify/functions/";
let getUrl = name => {
  return API_URL + name;
};

let getHello = async () => {
  // try {
  // 	const response = await axios.get(getUrl('hello'));
  // 	console.log(response);
  // } catch (error) {
  // 	console.error(error);
  // }
};

export { getHello };
