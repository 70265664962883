import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import '../css/add.css';
import { Link } from 'gatsby';
import { getHello } from '../utils/service';
import { Clients } from '../components/Clients';
import { graphql } from 'gatsby';
import { ContactForm } from './contact';

const LandingBanner = () => (
	<div className="points">
		<div className="flex justify-center items-center container text-black mx-auto p-6 text-left pb-40 pt-24 md:pt-18 sm:pt-18">
			<div className="max-w-lg">
				<div className="pb-3">
					<svg height="7" width="40">
						<line x1="0" y1="0" x2="100" y2="0" style={{ stroke: '#1CF5BA', strokeWidth: '6' }} />
					</svg>{' '}
					<span className="font-medium tracking-wide ml-2">UNBREAKABLE STUDIO</span>
				</div>
				<h1 className="xl:text-7xl lg:text-7xl md:text-7xl text-6xl pb-5">
					{/* <span className="underline--magical">Building the future</span>. */}
					Building the future.
				</h1>
				<h2 className="text-4xl font-thin">We are a strategy, design, and engineering studio from latam.</h2>
				<div className="mt-12">
					<Link to="/contact" className="tracking-wide bg-black p-5 text-white no-underline workWU font-bold">
						WORK WITH US
					</Link>
				</div>
			</div>
		</div>
	</div>
);

const SecondBanner = () => (
	<div className="react-cover">
		<div className="flex justify-center items-center container text-black mx-auto p-6 text-left pb-40 pt-24 md:pt-18 sm:pt-18">
			<div className="max-w-lg">
				<div className="pb-5">
					<svg height="7" width="40">
						<line x1="0" y1="0" x2="100" y2="0" style={{ stroke: '#1CF5BA', strokeWidth: '6' }} />
					</svg>{' '}
					<span className="font-medium tracking-wide text-white ml-2">WHAT WE DO</span>
				</div>
				<h1 className="xl:text-5xl lg:text-5xl md:text-5xl text-4xl pb-5 text-white">
					Strategy. Design. Engineering.
				</h1>
				<h2 className="text-2xl font-thin text-black-us-light leading-normal">
					Unbreakable Studio transforms businesses by building best-in-class data-driven experiences,
					products, and workflows rooted in strong creative, technology, and organizational strategy. Our
					multi-disciplinary team of analysts, designers, and engineers has been helping our clients solve
					problems for over 5 years.
				</h2>
				<div className="mt-8 flex flex-wrap">
					<div className="mt-3 flex-grow xl:w-1/3 lg:w-1/3 md:w-1/3 w-auto">
						<span className="text-white text-2xl font-medium">Engineering</span>
						<div className="text-black-us-light mt-5">
							<span className="block text-xl font-thin pb-4 pl-6">Frontend Engineering</span>
							<span className="block text-xl font-thin pb-4 pl-6">Backend Engineering</span>
							<span className="block text-xl font-thin pb-4 pl-6">Performance Auditing</span>
							<span className="block text-xl font-thin pb-4 pl-6">Automation</span>
						</div>
					</div>

					<div className="mt-3 flex-grow xl:w-1/3 lg:w-1/3 md:w-1/3 w-auto">
						<span className="text-white text-2xl font-medium">Design</span>
						<div className="text-black-us-light mt-5">
							<span className="block text-xl font-thin pb-4 pl-6">User Experience</span>
							<span className="block text-xl font-thin pb-4 pl-6">Interactive Prototyping</span>
							<span className="block text-xl font-thin pb-4 pl-6">Design Systems & Workflows</span>
							<span className="block text-xl font-thin pb-4 pl-6">Data Visualization</span>
						</div>
					</div>
					<div className="mt-3 flex-grow xl:w-1/3 lg:w-1/3 md:w-1/3 w-auto">
						<span className="text-white text-2xl font-medium">Strategy</span>
						<div className="text-black-us-light mt-5">
							<span className="block text-xl font-thin pb-4 pl-6">Privacy & Security</span>
							<span className="block text-xl font-thin pb-4 pl-6">Workshops & Conferences</span>
							<span className="block text-xl font-thin pb-4 pl-6">Developer Tooling </span>
							<span className="block text-xl font-thin pb-4 pl-6">Cloud Infrastructure</span>
							<span className="block text-xl font-thin pb-4 pl-6">Systems Architecture</span>
						</div>
					</div>
				</div>
				<div className="mt-8">
					<Link
						to="/contact"
						className="tracking-wide bg-green-us-light p-5 text-black hover:text-green-us-light no-underline hover-button2 font-bold"
					>
						GET IN TOUCH
					</Link>
				</div>
			</div>
		</div>
	</div>
);

const TercerBanner = () => (
	<div className="react-cover-white">
		<div className="flex justify-center items-center container text-black mx-auto p-6 text-left pb-40 pt-24 md:pt-18 sm:pt-18">
			<div className="max-w-lg">
				<div className="pb-5">
					<svg height="7" width="40">
						<line x1="0" y1="0" x2="100" y2="0" style={{ stroke: '#1CF5BA', strokeWidth: '6' }} />
					</svg>{' '}
					<span className="font-medium tracking-wide text-black ml-2">OUT PROCESS</span>
				</div>
				<h1 className="xl:text-5xl lg:text-5xl md:text-5xl text-4xl pb-5 text-black">
					Small teams. Huge results.
				</h1>
				<h2 className="text-2xl font-thin text-black leading-normal">
					Small, adaptive, interdisciplinary teams work in close collaboration with our clients. Our
					user-centric approach focuses on creating a seamless experience across all digital touchpoints. By
					leveraging a combination of open source software, 3rd-party solutions, and proprietary tools, we
					deliver effective, sustainable, and performant results that drive value.
				</h2>
				<div className="mt-8 flex flex-wrap">
					<div className="mt-3 flex-grow xl:w-1/3 lg:w-1/3 md:w-1/3 w-auto">
						<span className="text-black text-2xl font-medium">How We Work</span>
						<div className="text-black mt-5">
							<span className="block text-xl font-thin pb-4 pl-6">In small teams</span>
							<span className="block text-xl font-thin pb-4 pl-6">At a sustainable pace</span>
							<span className="block text-xl font-thin pb-4 pl-6">
								With open source and proprietary tools
							</span>
						</div>
					</div>

					<div className="mt-3 flex-grow xl:w-1/3 lg:w-1/3 md:w-1/3 w-auto">
						<span className="text-black text-2xl font-medium">Focus by priorities</span>
						<div className="text-black mt-5">
							<span className="block text-xl font-thin pb-4 pl-6">
								In weekly iterations of focused work
							</span>
							<span className="block text-xl font-thin pb-4 pl-6">
								On new or existing projects and products
							</span>
							<span className="block text-xl font-thin pb-4 pl-6">Flexibly based on project needs</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
);

const Contact = () => (
	<div className="react-cover">
		<div className="flex justify-center items-center container text-white mx-auto p-6 text-left pb-40 pt-24 md:pt-18 sm:pt-18">
			<div className="max-w-lg">
				<div className="pb-5">
					<div className="pb-5">
						<svg height="7" width="40">
							<line x1="0" y1="0" x2="100" y2="0" style={{ stroke: '#1CF5BA', strokeWidth: '6' }} />
						</svg>{' '}
						<span className="font-medium tracking-wide ml-2 uppercase">contact us</span>
					</div>
					<h1 className="xl:text-7xl lg:text-7xl md:text-7xl text-6xl pb-5">Let's talk</h1>
					<h2 className="text-4xl font-thin">Got a project? Give us a shout below and we'll be in touch.</h2>
					<ContactForm black />
				</div>
			</div>
		</div>
	</div>
);

const CuartoBanner = ({ data }) => (
	<div className="react-cover-white">
		<div className="flex justify-center items-center container text-black mx-auto p-6 text-left pb-40 pt-24 md:pt-18 sm:pt-18">
			<div className="max-w-lg">
				<div className="pb-5">
					<svg height="7" width="40">
						<line x1="0" y1="0" x2="100" y2="0" style={{ stroke: '#1CF5BA', strokeWidth: '6' }} />
					</svg>{' '}
					<span className="font-medium tracking-wide text-black ml-2">WHO WE WORK WITH</span>
				</div>
				<h1 className="xl:text-5xl lg:text-5xl md:text-5xl text-4xl pb-5 text-black">Clients & Partners</h1>
				<h2 className="text-2xl font-thin text-black-us-light leading-normal">
					We feel privileged to work with some extraordinary organizations.
				</h2>
				{/* CLIENTES */}
				<div className="mt-6">
					<Clients data={data} />
				</div>
				{/* CLIENTES */}
				<div className="mt-8">
					<Link
						to="/contact"
						className="tracking-wide bg-green-us-light p-5 text-black hover:text-green-us-light no-underline hover-button2 font-bold"
					>
						GET IN TOUCH
					</Link>
				</div>
			</div>
		</div>
	</div>
);

const IndexPage = ({ data }) => {
	getHello();
	return (
		<Layout>
			<SEO
				title="Home"
				keywords={[
					`websites`,
					`webapps`,
					`webapps guatemala`,
					`react`,
					`unbreakable`,
					`studio`,
					`serverless`,
					`guatemala`,
					`node`,
					`node guatemala`,
					`serverless guatemala`,
					`go guatemala`,
					`react guatemala`,
					`gatsby guatemala`
				]}
			/>
			<LandingBanner />
			<SecondBanner />
			<TercerBanner />
			<Contact />
			<CuartoBanner data={data} />
		</Layout>
	);
};

export const query = graphql`
	{
		rem2: file(relativePath: { eq: "REM2.png" }) {
			childImageSharp {
				fluid(maxWidth: 600) {
					...GatsbyImageSharpFluid_tracedSVG
				}
			}
		}
		know: file(relativePath: { eq: "KNOW.png" }) {
			childImageSharp {
				fluid(maxWidth: 600) {
					...GatsbyImageSharpFluid_tracedSVG
				}
			}
		}
		rem3: file(relativePath: { eq: "REM3.png" }) {
			childImageSharp {
				fluid(maxWidth: 600) {
					...GatsbyImageSharpFluid_tracedSVG
				}
			}
		}
		pastelu: file(relativePath: { eq: "PAST.png" }) {
			childImageSharp {
				fluid(maxWidth: 600) {
					...GatsbyImageSharpFluid_tracedSVG
				}
			}
		}
		alpha1: file(relativePath: { eq: "ALPHAC1.png" }) {
			childImageSharp {
				fluid(maxWidth: 600) {
					...GatsbyImageSharpFluid_tracedSVG
				}
			}
		}
	}
`;

export default IndexPage;
